exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-api-pricing-tsx": () => import("./../../../src/pages/api-pricing.tsx" /* webpackChunkName: "component---src-pages-api-pricing-tsx" */),
  "component---src-pages-bass-the-fish-tsx": () => import("./../../../src/pages/bass-the-fish.tsx" /* webpackChunkName: "component---src-pages-bass-the-fish-tsx" */),
  "component---src-pages-docs-tsx": () => import("./../../../src/pages/docs.tsx" /* webpackChunkName: "component---src-pages-docs-tsx" */),
  "component---src-pages-examples-make-meeting-transcription-summarization-tsx": () => import("./../../../src/pages/examples/make-meeting-transcription-summarization.tsx" /* webpackChunkName: "component---src-pages-examples-make-meeting-transcription-summarization-tsx" */),
  "component---src-pages-examples-meeting-transcription-summarization-notion-integration-tsx": () => import("./../../../src/pages/examples/meeting-transcription-summarization-notion-integration.tsx" /* webpackChunkName: "component---src-pages-examples-meeting-transcription-summarization-notion-integration-tsx" */),
  "component---src-pages-examples-open-source-transcript-player-with-options-tsx": () => import("./../../../src/pages/examples/open-source-transcript-player-with-options.tsx" /* webpackChunkName: "component---src-pages-examples-open-source-transcript-player-with-options-tsx" */),
  "component---src-pages-examples-oss-assemblyai-api-transcript-seeker-tsx": () => import("./../../../src/pages/examples/oss-assemblyai-api-transcript-seeker.tsx" /* webpackChunkName: "component---src-pages-examples-oss-assemblyai-api-transcript-seeker-tsx" */),
  "component---src-pages-examples-oss-gladia-api-transcript-seeker-tsx": () => import("./../../../src/pages/examples/oss-gladia-api-transcript-seeker.tsx" /* webpackChunkName: "component---src-pages-examples-oss-gladia-api-transcript-seeker-tsx" */),
  "component---src-pages-examples-oss-transcript-seeker-tsx": () => import("./../../../src/pages/examples/oss-transcript-seeker.tsx" /* webpackChunkName: "component---src-pages-examples-oss-transcript-seeker-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-how-we-make-money-tsx": () => import("./../../../src/pages/how-we-make-money.tsx" /* webpackChunkName: "component---src-pages-how-we-make-money-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lets-do-this-tsx": () => import("./../../../src/pages/lets-do-this.tsx" /* webpackChunkName: "component---src-pages-lets-do-this-tsx" */),
  "component---src-pages-manifesto-tsx": () => import("./../../../src/pages/manifesto.tsx" /* webpackChunkName: "component---src-pages-manifesto-tsx" */),
  "component---src-pages-meeting-bots-are-the-new-norm-tsx": () => import("./../../../src/pages/meeting-bots-are-the-new-norm.tsx" /* webpackChunkName: "component---src-pages-meeting-bots-are-the-new-norm-tsx" */),
  "component---src-pages-our-traction-tsx": () => import("./../../../src/pages/our-traction.tsx" /* webpackChunkName: "component---src-pages-our-traction-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-replace-any-meeting-stakeholder-tsx": () => import("./../../../src/pages/replace-any-meeting-stakeholder.tsx" /* webpackChunkName: "component---src-pages-replace-any-meeting-stakeholder-tsx" */),
  "component---src-pages-speaking-meeting-bots-tsx": () => import("./../../../src/pages/speaking-meeting-bots.tsx" /* webpackChunkName: "component---src-pages-speaking-meeting-bots-tsx" */),
  "component---src-pages-talk-to-us-tsx": () => import("./../../../src/pages/talk-to-us.tsx" /* webpackChunkName: "component---src-pages-talk-to-us-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-the-ask-tsx": () => import("./../../../src/pages/the-ask.tsx" /* webpackChunkName: "component---src-pages-the-ask-tsx" */),
  "component---src-pages-the-plan-tsx": () => import("./../../../src/pages/the-plan.tsx" /* webpackChunkName: "component---src-pages-the-plan-tsx" */),
  "component---src-pages-use-cases-tsx": () => import("./../../../src/pages/use-cases.tsx" /* webpackChunkName: "component---src-pages-use-cases-tsx" */)
}

